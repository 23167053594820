














import Vue from 'vue'
import { Store } from 'vuex'
import ModalHead from './ModalHead.vue'
import ModalBody from './ModalBody.vue'
import ModalFooter from './ModalFooter.vue'

import { rgbaConverter } from './helpers/helpers'

export default Vue.extend({
  name: 'Modal',
  components: { ModalHead, ModalBody, ModalFooter },
  computed: {
    store(): IState {
      return (this.$store as Store<IState>).state
    },
    show(): boolean {
      return this.$store.state.showModal
    },
    bodyBackground(): string {
      return rgbaConverter(this.store.slidesConfig.slideBackground)
    }
  }
})
