
















import Vue from 'vue'

export default Vue.extend({
  name: 'FreeFieldTextarea',
  props: {
    field: {
      type: Object,
      required: true
    },
    slideId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      textareaValue: ''
    }
  },
  computed: {
    fieldId(): string {
      return `${this.$store.state.clickFlowID}-textarea-${this.field.id}`
    }
  },
  methods: {
    updateValue() {
      this.$store.commit('updateValue', {
        slideId: this.slideId,
        field: this.field,
        fieldValue: this.textareaValue
      })
    }
  }
})
