



















import Vue from 'vue'
import { Store } from 'vuex'

import {
  validateMin,
  validateMax,
  validateStep
} from './helpers/validateHelpers'

export default Vue.extend({
  name: 'FreeFieldNumber',
  props: {
    field: {
      type: Object,
      required: true
    },
    slideId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      numberValue: this.field.value,
      validated: true
    }
  },
  computed: {
    storeState(): IState {
      return (this.$store as Store<IState>).state
    },
    fieldId(): string {
      return `${this.storeState.clickFlowID}-number-${this.field.id}`
    },
    validateError(): string {
      return !this.validated ? 'cfa-free-field-number-validate-error' : ''
    },
    optionResult(): INumber & {
      reply: string
    } {
      return this.$store.getters.getOptionsResultByFieldIdAndSlideId(
        this.field.id,
        this.slideId
      )
    }
  },
  methods: {
    updateNumber() {
      if (
        validateMin(this.optionResult, this.numberValue) &&
        validateMax(this.optionResult, this.numberValue) &&
        validateStep(this.optionResult, this.numberValue)
      ) {
        this.validated = true
        this.$store.commit('updateValue', {
          slideId: this.slideId,
          field: this.field,
          fieldValue: this.numberValue
        })
      } else {
        this.validated = false
        this.$store.commit('updateValue', {
          slideId: this.slideId,
          field: this.field,
          fieldValue: ''
        })
      }
    }
  }
})
