




















import Vue from 'vue'
import {
  validateEmailInput,
  validateMinLength,
  validateMaxLength
} from './helpers/validateHelpers'

export default Vue.extend({
  name: 'FreeFieldEmail',
  props: {
    field: {
      type: Object,
      required: true
    },
    slideId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      emailValue: '',
      validated: true
    }
  },
  computed: {
    fieldDOMId(): string {
      return `${this.$store.state.clickFlowID}-text-${this.field.id}`
    },
    validateError(): string {
      return !this.validated ? 'cfa-free-field-email-validate-error' : ''
    },
    optionResult(): IEMail & {
      reply: string
    } {
      return this.$store.getters.getOptionsResultByFieldIdAndSlideId(
        this.field.id,
        this.slideId
      )
    }
  },
  methods: {
    updateValue() {
      if (
        validateEmailInput(this.emailValue) &&
        validateMinLength(this.optionResult, this.emailValue) &&
        validateMaxLength(this.optionResult, this.emailValue)
      ) {
        this.validated = true
        this.$store.commit('updateValue', {
          slideId: this.slideId,
          field: this.field,
          fieldValue: this.emailValue
        })
      } else {
        this.validated = false
        if (this.emailValue === '') {
          this.validated = true
        }
        this.$store.commit('updateValue', {
          slideId: this.slideId,
          field: this.field,
          fieldValue: ''
        })
      }
    }
  }
})
