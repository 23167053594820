import { sendFormToChocobrain4 } from './../cB4FormPlugin'

export const demoConfig: IUserConfig = {
  openModalCallback: param => console.log(param),
  clickFlowFinishedCallback: appResult =>
    sendFormToChocobrain4({ appResult, formId: 'msf-test-form' }),
  openModalByCards: false,
  clickFlowID: 'msf-test',
  clickFlowTitle: 'MIT WENIGEN KLICKS ZU IHREM TERMIN',
  clickFlowDescription:
    'Bitte füllen Sie alle Felder aus, die mit * gekennzeichnet sind.',
  versionClickFlow: 1,
  pathnameBackupForm: '/formziel',
  fontSource: 'Arial',
  slidesConfig: {
    slideBackground: { red: 255, green: 255, blue: 255, alpha: 1 },
    nextButton: {
      showOnSlide: true,
      disableWithoutInput: false,
      normalStyle: {
        background: {
          color: {
            red: 102,
            green: 152,
            blue: 197,
            alpha: 1
          },
          image: {
            src: undefined,
            alt: undefined
          }
        },
        font: {
          family: 'Font Family',
          weight: 100,
          size: 10
        },
        borderColor: {
          red: 140,
          green: 15,
          blue: 17,
          alpha: 1
        },
        borderWidth: 0,
        textColor: {
          red: 255,
          green: 255,
          blue: 255,
          alpha: 1
        }
      },
      hoverStyle: {
        background: {
          color: {
            red: 51,
            green: 118,
            blue: 177,
            alpha: 1
          },
          image: {
            src: undefined,
            alt: undefined
          }
        },
        font: {
          family: 'Font Family',
          weight: 100,
          size: 10
        },
        borderColor: {
          red: 1,
          green: 1,
          blue: 1,
          alpha: 1
        },
        borderWidth: 0,
        textColor: {
          red: 255,
          green: 255,
          blue: 255,
          alpha: 1
        }
      },
      label: 'Weiter'
    },
    submitButton: {
      normalStyle: {
        background: {
          color: {
            red: 245,
            green: 78,
            blue: 103,
            alpha: 1
          },
          image: {
            src: undefined,
            alt: undefined
          }
        },
        font: {
          family: 'Font Family',
          weight: 100,
          size: 10
        },
        borderColor: {
          red: 14,
          green: 15,
          blue: 17,
          alpha: 1
        },
        borderWidth: 0,
        textColor: {
          red: 255,
          green: 255,
          blue: 255,
          alpha: 1
        }
      },
      hoverStyle: {
        background: {
          color: {
            red: 245,
            green: 78,
            blue: 103,
            alpha: 0.8
          },
          image: {
            src: undefined,
            alt: undefined
          }
        },
        font: {
          family: 'Font Family',
          weight: 100,
          size: 10
        },
        borderColor: {
          red: 1,
          green: 1,
          blue: 1,
          alpha: 1
        },
        borderWidth: 0,
        textColor: {
          red: 255,
          green: 255,
          blue: 255,
          alpha: 1
        }
      }
    },
    question: {
      background: {
        color: {
          red: 44,
          green: 45,
          blue: 40,
          alpha: 0.5
        },
        image: {
          src: undefined,
          alt: undefined
        }
      },
      font: {
        family: 'Font Family',
        weight: 100,
        size: 3
      },
      borderColor: {
        red: 130,
        green: 134,
        blue: 140,
        alpha: 1
      },
      borderWidth: 1,
      textColor: {
        red: 77,
        green: 77,
        blue: 77,
        alpha: 1
      }
    },
    option: {
      background: {
        color: {
          red: 239,
          green: 242,
          blue: 248,
          alpha: 1
        },
        image: {
          src: undefined,
          alt: undefined
        }
      },
      font: {
        family: 'Times New Roman',
        weight: 600,
        size: 2
      },
      borderColor: {
        red: 51,
        green: 118,
        blue: 177,
        alpha: 1
      },
      borderWidth: 8,
      textColor: {
        red: 77,
        green: 77,
        blue: 77,
        alpha: 1
      }
    },
    title: {
      background: {
        color: {
          red: 202,
          green: 203,
          blue: 205,
          alpha: 0.5
        },
        image: {
          src: undefined,
          alt: undefined
        }
      },
      font: {
        family: 'Font Family',
        weight: 200,
        size: 9
      },
      borderColor: {
        red: 33,
        green: 34,
        blue: 45,
        alpha: 1
      },
      borderWidth: 2,
      textColor: {
        red: 77,
        green: 77,
        blue: 77,
        alpha: 1
      }
    }
  },
  head: {
    logo: {
      image: {
        src: undefined,
        alt: 'logo'
      },
      link: {
        src: 'Link Source',
        title: 'Link Title',
        target: '_self'
      }
    },
    closeButton: {
      normalStyle: {
        background: {
          color: {
            red: 255,
            green: 255,
            blue: 255,
            alpha: 1
          },
          image: {
            src: undefined,
            alt: undefined
          }
        },
        font: {
          family: 'Font Family',
          weight: 100,
          size: 4
        },
        borderColor: {
          red: 77,
          green: 77,
          blue: 77,
          alpha: 1
        },
        borderWidth: 0,
        textColor: {
          red: 77,
          green: 77,
          blue: 77,
          alpha: 1
        }
      },
      hoverStyle: {
        background: {
          color: {
            red: 239,
            green: 242,
            blue: 248,
            alpha: 0.8
          },
          image: {
            src: undefined,
            alt: undefined
          }
        },
        font: {
          family: 'Font Family',
          weight: 100,
          size: 10
        },
        borderColor: {
          red: 77,
          green: 77,
          blue: 77,
          alpha: 1
        },
        borderWidth: 0,
        textColor: {
          red: 77,
          green: 77,
          blue: 77,
          alpha: 0.8
        }
      },
      label: '&times;'
    },
    background: {
      color: {
        red: 255,
        green: 255,
        blue: 255,
        alpha: 1
      },
      image: {
        src: undefined,
        alt: undefined
      }
    }
  },
  slides: [
    {
      position: 1,
      id: 'slide1',
      targetSlideId: 'slide2',
      titleContent: 'Wählen Sie Ihr Gebiet aus.',
      titleHint: 'Einfachauswahl, eigene Angaben in Option C, Pflichtfeld.',
      type: 'select',
      answerOptions: [
        {
          id: 'slide1Answer1',
          label: 'Option A',
          hint: undefined,
          image: {
            src: undefined,
            alt: 'Option A'
          },
          selected: false,
          nextSlide: undefined
        },
        {
          id: 'slide1Answer2',
          label: 'Option B',
          hint: undefined,
          image: {
            src: undefined,
            alt: 'Option B'
          },
          selected: false,
          nextSlide: undefined
        },
        {
          id: 'slide1Answer3_textInput',
          label: 'Option C',
          hint: undefined,
          image: {
            src: undefined,
            alt: 'Option C'
          },
          selected: false,
          nextSlide: undefined
        }
      ],
      multipleSelect: false,
      required: true,
      formFieldName: 'Gebiet'
    },
    {
      position: 2,
      id: 'slide2',
      targetSlideId: 'slide3',
      titleContent: 'Wählen Sie Ihre Interessen aus.',
      titleHint: 'Mehrfachauswahl, Vorauswahl',
      type: 'select',
      answerOptions: [
        {
          id: 'slide2Answer1',
          label: 'Option A',
          hint: undefined,
          image: {
            src: undefined,
            alt: 'Option A'
          },
          selected: true,
          nextSlide: undefined
        },
        {
          id: 'slide2Answer2',
          label: 'Option B',
          hint: undefined,
          image: {
            src: undefined,
            alt: 'Option B'
          },
          selected: false,
          nextSlide: undefined
        },
        {
          id: 'slide2Answer3',
          label: 'Option C',
          hint: undefined,
          image: {
            src: undefined,
            alt: 'Option C'
          },
          selected: true,
          nextSlide: undefined
        }
      ],
      multipleSelect: true,
      required: false,
      formFieldName: 'Schwerpunkt'
    },
    {
      position: 3,
      id: 'slide3',
      targetSlideId: 'slide4',
      titleContent: 'Geben Sie zusätzliche Informationen an.',
      titleHint:
        'Feldertypen, Pflichtfelder, Tooltip, Autocomplete, Placeholder',
      type: 'freeField',
      columns: 2,
      fields: [
        {
          id: 'slide3Field1',
          required: true,
          label: 'Textfeld',
          hint: 'Autocomplete Vorname',
          autocomplete: 'given-name',
          positionColumn: 1,
          positionRow: 1,
          columnSpan: 1,
          type: 'input',
          inputType: 'text',
          value: undefined,
          maxlength: 20,
          minlength: 3,
          placeholder: 'Max',
          size: undefined,
          formFieldName: 'Textfeld',
          pattern: /^[a-z ]+$/i
        },
        {
          id: 'slide3Field2',
          required: true,
          label: 'E-Mail',
          hint: undefined,
          autocomplete: 'email',
          positionColumn: 1,
          positionRow: 2,
          columnSpan: 1,
          type: 'input',
          inputType: 'email',
          value: undefined,
          maxlength: 56,
          minlength: 6,
          multiple: false,
          placeholder: 'max.mustermann@muster.de',
          size: undefined,
          formFieldName: 'Email'
        },
        {
          id: 'slide3Field3',
          required: true,
          label: 'Telefonnummer',
          hint: undefined,
          autocomplete: 'tel',
          positionColumn: 2,
          positionRow: 2,
          columnSpan: 1,
          type: 'input',
          inputType: 'tel',
          value: undefined,
          maxlength: 20,
          minlength: 3,
          placeholder: undefined,
          size: undefined,
          formFieldName: 'Telefonnummer'
        },
        {
          id: 'slide3Field4',
          required: false,
          label: 'Textarea',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 1,
          positionRow: 3,
          columnSpan: 2,
          type: 'textarea',
          value: undefined,
          cols: 140,
          rows: 10,
          placeholder: undefined,
          formFieldName: 'Textarea'
        },
        {
          id: 'slide3Field5',
          required: true,
          label: 'Nummer',
          hint: 'Hinweis für Nummern.',
          autocomplete: undefined,
          positionColumn: 1,
          positionRow: 4,
          columnSpan: 1,
          type: 'input',
          inputType: 'number',
          value: undefined,
          max: 200,
          min: 5,
          placeholder: '123',
          step: 5,
          formFieldName: 'Nummer'
        },
        {
          id: 'slide3Field6',
          required: true,
          label: 'Select',
          hint: 'Vorauswahl der Option C',
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 4,
          columnSpan: 1,
          type: 'select',
          multiple: false,
          options: [
            {
              label: 'Option A',
              id: 'slide3Field6A',
              checked: false
            },
            {
              label: 'Option B',
              id: 'slide3Field6B',
              checked: false
            },
            {
              label: 'Option C',
              id: 'slide3Field6C',
              checked: true
            }
          ],
          formFieldName: 'Select'
        },
        {
          id: 'slide3Field7',
          required: true,
          label: 'Checkbox',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 1,
          positionRow: 5,
          columnSpan: 1,
          type: 'input',
          inputType: 'checkbox',
          privacy: false,
          options: [
            {
              label: 'Option A',
              id: 'slide3Field7A',
              checked: true
            },
            {
              label: 'Option B',
              id: 'slide3Field7B',
              checked: false
            }
          ],
          formFieldName: 'Checkbox'
        },
        {
          id: 'slide3Field8',
          required: true,
          label: 'Radio',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 5,
          columnSpan: 1,
          type: 'input',
          inputType: 'radio',
          options: [
            {
              label: 'Option A',
              id: 'slide3Field8A',
              checked: false
            },
            {
              label: 'Option B',
              id: 'slide3Field8B',
              checked: false
            }
          ],
          formFieldName: 'Radio'
        },
        {
          id: 'slide3Field9',
          required: false,
          label: 'Dokument',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 1,
          positionRow: 6,
          columnSpan: 1,
          type: 'input',
          inputType: 'file',
          accept: 'img',
          capture: 'capture',
          multiple: false,
          value: undefined,
          name: undefined,
          formFieldName: 'Dokument'
        },
        {
          id: 'slide3Field10',
          required: false,
          label: 'Range',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 6,
          columnSpan: 1,
          formFieldName: 'Range',
          type: 'input',
          inputType: 'range',
          min: 0,
          max: 500,
          step: 50,
          value: undefined,
          base: 'Einheiten'
        }
      ]
    },
    {
      position: 4,
      id: 'slide4',
      targetSlideId: 'slide5',
      titleContent: 'Geben Sie zusätzliche Informationen an2.',
      titleHint:
        'Feldertypen, Pflichtfelder, Tooltip, Autocomplete, Placeholder',
      type: 'freeField',
      columns: 2,
      fields: [
        {
          id: 'slide4Field1',
          required: true,
          label: 'Textfeld',
          hint: 'Autocomplete Vorname',
          autocomplete: 'given-name',
          positionColumn: 1,
          positionRow: 1,
          columnSpan: 1,
          type: 'input',
          inputType: 'text',
          value: undefined,
          maxlength: 20,
          minlength: 3,
          placeholder: 'Max',
          size: undefined,
          formFieldName: 'Textfeld2'
        },
        {
          id: 'slide4Field2',
          required: false,
          label: 'E-Mail',
          hint: undefined,
          autocomplete: 'email',
          positionColumn: 1,
          positionRow: 2,
          columnSpan: 1,
          type: 'input',
          inputType: 'email',
          value: undefined,
          maxlength: 56,
          minlength: 6,
          multiple: false,
          placeholder: 'max.mustermann@muster.de',
          size: undefined,
          formFieldName: 'Email2'
        },
        {
          id: 'slide4Field3',
          required: false,
          label: 'Telefonnummer',
          hint: undefined,
          autocomplete: 'tel',
          positionColumn: 2,
          positionRow: 2,
          columnSpan: 1,
          type: 'input',
          inputType: 'tel',
          value: undefined,
          maxlength: 20,
          minlength: 3,
          placeholder: undefined,
          size: undefined,
          formFieldName: 'Telefonnummer2'
        },
        {
          id: 'slide4Field4',
          required: false,
          label: 'Textarea',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 1,
          positionRow: 3,
          columnSpan: 2,
          type: 'textarea',
          value: undefined,
          cols: 140,
          rows: 10,
          placeholder: undefined,
          formFieldName: 'Textarea2'
        },
        {
          id: 'slide4Field5',
          required: true,
          label: 'Nummer',
          hint: 'Hinweis für Nummern.',
          autocomplete: undefined,
          positionColumn: 1,
          positionRow: 4,
          columnSpan: 1,
          type: 'input',
          inputType: 'number',
          value: undefined,
          max: 200,
          min: 5,
          placeholder: '123',
          step: 5,
          formFieldName: 'Nummer2'
        },
        {
          id: 'slide4Field6',
          required: true,
          label: 'Select',
          hint: 'Vorauswahl der Option C',
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 4,
          columnSpan: 1,
          type: 'select',
          multiple: false,
          options: [
            {
              label: 'Option A',
              id: 'slide3Field6A',
              checked: false
            },
            {
              label: 'Option B',
              id: 'slide3Field6B',
              checked: false
            },
            {
              label: 'Option C',
              id: 'slide3Field6C',
              checked: true
            }
          ],
          formFieldName: 'Select2'
        },
        {
          id: 'slide4Field7',
          required: true,
          label: 'Checkbox',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 1,
          positionRow: 5,
          columnSpan: 1,
          type: 'input',
          inputType: 'checkbox',
          privacy: false,
          options: [
            {
              label: 'Option A',
              id: 'slide4Field7A',
              checked: true
            },
            {
              label: 'Option B',
              id: 'slide4Field7B',
              checked: false
            }
          ],
          formFieldName: 'Checkbox2'
        },
        {
          id: 'slide4Field8',
          required: true,
          label: 'Radio',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 5,
          columnSpan: 1,
          type: 'input',
          inputType: 'radio',
          options: [
            {
              label: 'Option A',
              id: 'slide4Field8A',
              checked: false
            },
            {
              label: 'Option B',
              id: 'slide4Field8B',
              checked: false
            }
          ],
          formFieldName: 'Radio2'
        },
        {
          id: 'slide4Field9',
          required: false,
          label: 'Dokument',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 1,
          positionRow: 6,
          columnSpan: 1,
          type: 'input',
          inputType: 'file',
          accept: 'img',
          capture: 'capture',
          multiple: false,
          value: undefined,
          name: undefined,
          formFieldName: 'Dokument2'
        },
        {
          id: 'slide4Field10',
          required: false,
          label: 'Range',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 6,
          columnSpan: 1,
          formFieldName: 'Range2',
          type: 'input',
          inputType: 'range',
          min: 0,
          max: 500,
          step: 50,
          value: undefined,
          base: 'Einheiten'
        },
        {
          id: 'slide4Field11',
          required: true,
          label: 'Abschicken',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 7,
          columnSpan: 1,
          type: 'input',
          inputType: 'submit',
          formtarget: undefined,
          formFieldName: 'Abschicken'
        }
      ]
    },
    {
      position: 5,
      id: 'slide5',
      targetSlideId: undefined,
      titleContent: 'Vielen Dank für Ihre Anfrage!',
      titleHint: 'Sie können die App nun schließen.',
      type: 'freeField',
      columns: 1,
      fields: []
    }
  ],
  footer: {
    logos: [
      {
        image: { src: 'Image Source', alt: '2' },
        link: {
          src: 'Link Source',
          title: 'Link Title',
          target: '_self'
        }
      }
    ],
    background: {
      color: {
        red: 239,
        green: 242,
        blue: 248,
        alpha: 1
      },
      image: undefined
    },
    font: {
      family: 'Font Family',
      weight: 100,
      size: 10
    }
  }
}
