





































import Vue from 'vue'
import { Store } from 'vuex'

import { rgbaConverter, openModal } from './helpers/helpers'
import FreeFieldText from './FreeFieldText.vue'

export default Vue.extend({
  name: 'SlideCard',
  components: {
    FreeFieldText
  },
  props: {
    slideId: {
      type: String,
      required: true
    },
    answerOptionId: {
      type: String,
      required: true
    }
  },
  computed: {
    storeState(): IState {
      return (this.$store as Store<IState>).state
    },
    slide(): ISelectSlide {
      return (this.$store as Store<IState>).getters.getSlideById(this.slideId)
    },
    answerOption(): IAnswerOption {
      return (this.$store as Store<
        IState
      >).getters.getAnswerOptionByIdAndSlideId(
        this.answerOptionId,
        this.slideId
      )
    },
    optionResult(): IAnswerOption & {
      reply: string
    } {
      return (this.$store as Store<
        IState
      >).getters.getOptionsResultByIdAndSlideId(
        this.answerOptionId,
        this.slideId
      )
    },
    borderWidth(): string {
      return `${this.storeState.slidesConfig.option.borderWidth}px`
    },
    borderColor(): string {
      let color = !this.optionResult.selected
        ? // TODO: config needs unselected color
          { red: 237, green: 237, blue: 237, alpha: 1 }
        : this.storeState.slidesConfig.option.borderColor
      return rgbaConverter(color)
    },
    cardBorder(): string {
      return `${this.borderWidth} solid ${this.borderColor}`
    },
    cardBackground(): string {
      return rgbaConverter(this.storeState.slidesConfig.option.background.color)
    },
    textColor(): string {
      return rgbaConverter(this.storeState.slidesConfig.option.textColor)
    },
    textFontFamily(): string {
      return `${this.storeState.slidesConfig.option.font
        ?.family as string}, Times, serif`
    },
    textFontSize(): string {
      return `${this.storeState.slidesConfig.option.font?.size as number}rem`
    },
    textBackgroundColor(): string {
      return rgbaConverter(this.storeState.slidesConfig.option.background.color)
    },
    titleWeight(): IFont['weight'] {
      return this.storeState.slidesConfig.option.font?.weight as number
    },
    textInput(): boolean {
      return this.answerOption.id.includes('textInput')
    },
    field(): IText {
      return {
        inputType: 'text',
        maxlength: 20,
        minlength: 0,
        positionRow: 0,
        columnSpan: 0,
        formFieldName: '',
        type: 'input',
        id: this.answerOptionId,
        required: false,
        label: this.answerOption.label,
        positionColumn: 0,
        value: undefined,
        placeholder: undefined,
        size: undefined,
        hint: undefined,
        autocomplete: undefined
      }
    },
    cardWrapperClass(): string {
      return `${this.$store.state.clickFlowID}-card-wrapper`
    },
    cardImageClass(): string {
      return `${this.$store.state.clickFlowID}-card-image`
    },
    cardLabelDOMClass(): string {
      return `${this.$store.state.clickFlowID}-card-label`
    },
    cardHintDOMClass(): string {
      return `${this.$store.state.clickFlowID}-card-hint`
    },
    imageWrapperDOMClass(): string {
      return `${this.$store.state.clickFlowID}-image-wrapper`
    }
  },
  methods: {
    click() {
      if (!this.slide.multipleSelect) {
        this.$store.commit('resetCards', {
          slideId: this.slideId
        })
        if (!this.storeState.showModal) {
          openModal()
        }
      }

      const reply = !this.optionResult.selected ? this.answerOption.label : ''
      const selected = !this.optionResult.selected

      // Needed when textInput, commitCard in FreeFieldText
      this.textInput
        ? this.handleInput(selected)
        : this.$store.commit('commitCard', {
            slideId: this.slideId,
            answerOptionId: this.answerOptionId,
            selected,
            reply
          })

      // Needed when NextButton isn't rendered on single select slides
      if (
        !this.slide.multipleSelect &&
        this.storeState.slidesConfig.nextButton.showOnSlide === false
      ) {
        if (!this.slide.targetSlideId) {
          throw new Error('Slide is missing `targetSlideId`!')
        }
        const nextSlideId = this.answerOption.nextSlide
          ? this.answerOption.nextSlide
          : this.slide.targetSlideId
        this.$store.commit('visibleSlideId', nextSlideId)
      }
    },
    handleInput(selected: boolean) {
      const text = document.getElementById(
        `${this.$store.state.clickFlowID}-text-${this.field.id}`
      ) as HTMLInputElement
      text.focus()

      if (text.value) {
        this.$store.commit('commitCard', {
          slideId: this.slideId,
          answerOptionId: this.answerOptionId,
          selected,
          reply: text.value
        })
      }
    }
  }
})
