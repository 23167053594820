




























import Vue from 'vue'

export default Vue.extend({
  name: 'FreeFieldCheckbox',
  props: {
    field: {
      type: Object,
      required: true
    },
    slideId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      checkedOptionsLabel: (this.field.options as IOption[])
        .filter(option => option.checked === true)
        .map(option => option.label)
    }
  },
  computed: {
    fieldClass(): string {
      return `${this.$store.state.clickFlowID}-${this.field.id}`
    },
    isRequired(): boolean {
      return this.field.required
    },
    privacyCheckbox(): boolean {
      return this.field.privacy
    },
    checkboxRequiredDOMClass(): string {
      return `${this.$store.state.clickFlowID}-privacy-required`
    }
  },
  methods: {
    change() {
      let reply = this.checkedOptionsLabel
      if (this.privacyCheckbox && this.checkedOptionsLabel.length === 1) {
        reply = ["ok"]
      }
      this.$store.commit('commitCheckboxOption', {
        slideId: this.slideId,
        field: this.field,
        optionsLabel: reply
      })
    },
    optionId(optionId: string): string {
      return `${this.$store.state.clickFlowID}-${this.field.id}-${optionId}`
    }
  }
})
