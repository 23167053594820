








































import Vue from 'vue'
import { Store } from 'vuex'

import { rgbaConverter, closeModal } from './helpers/helpers'

export default Vue.extend({
  name: 'ModalHead',
  data() {
    return {
      closeButtonHover: false
    }
  },
  computed: {
    store(): IState {
      return (this.$store as Store<IState>).state
    },
    headLogo(): ILogo {
      return this.store.head.logo
    },
    headLogoHasLink(): boolean {
      return this.headLogo.link != undefined ? true : false
    },
    headBackground(): string {
      return rgbaConverter(this.store.head.background.color)
    },
    closeButtonFontSize(): string {
      return `${this.closeButton.normalStyle.font?.size}rem`
    },
    closeButton(): IButton {
      return this.store.head.closeButton as IButton
    },
    closeButtonBackground(): string {
      return this.closeButtonHover
        ? rgbaConverter(this.closeButton.hoverStyle.background.color)
        : rgbaConverter(this.closeButton.normalStyle.background.color)
    },
    closeButtonTextColor(): string {
      return this.closeButtonHover
        ? rgbaConverter(this.closeButton.hoverStyle.textColor)
        : rgbaConverter(this.closeButton.normalStyle.textColor)
    },
    closeButtonBorder(): string {
      return this.closeButtonHover
        ? `${this.closeButton.hoverStyle.borderWidth}px solid ${rgbaConverter(
            this.closeButton.hoverStyle.borderColor
          )}`
        : `${this.closeButton.normalStyle.borderWidth}px solid ${rgbaConverter(
            this.closeButton.normalStyle.borderColor
          )}`
    },
    closeId(): string {
      return `${this.$store.state.clickFlowID}-close-button`
    },
    logoLinkStyle(): string {
      return this.headLogoHasLink ? 'cursor: pointer;' : ''
    },
    headDOMid(): string {
      return `${this.$store.state.clickFlowID}-head-content`
    },
    logoDOMId(): string {
      return `${this.$store.state.clickFlowID}-head-logo`
    }
  },
  methods: {
    clickClose() {
      closeModal()
    },
    clickLogo() {
      if (this.headLogoHasLink) {
        window.location.href = `${this.headLogo.link?.src}`
      }
    },
    hoverCloseButton() {
      this.closeButtonHover = !this.closeButtonHover
    }
  }
})
