import { sendFormToChocobrain4 } from '../cB4FormPlugin'

export const configMock: IUserConfig = {
  openModalCallback: param => console.log(param),
  clickFlowFinishedCallback: appResult =>
    sendFormToChocobrain4({
      appResult,
      formId: 'Kontaktformular CFF'
    }),
  openModalByCards: true,
  clickFlowID: 'cff-szhh',
  clickFlowTitle: 'BEI UNS GEHT ES UM IHRE GESUNDHEIT!',
  clickFlowDescription: undefined,
  versionClickFlow: 1,
  pathnameBackupForm: '/termin',
  fontSource: undefined,
  slidesConfig: {
    slideBackground: { red: 255, green: 255, blue: 255, alpha: 1 },
    nextButton: {
      showOnSlide: false,
      disableWithoutInput: false,
      normalStyle: {
        background: {
          color: { red: 97, green: 165, blue: 201, alpha: 1 },
          image: undefined
        },
        font: undefined,
        borderColor: { red: 97, green: 165, blue: 201, alpha: 1 },
        borderWidth: 1,
        textColor: { red: 255, green: 255, blue: 255, alpha: 1 }
      },
      hoverStyle: {
        background: {
          color: { red: 9, green: 72, blue: 100, alpha: 1 },
          image: undefined
        },
        font: undefined,
        borderColor: { red: 97, green: 165, blue: 201, alpha: 1 },
        borderWidth: 0,
        textColor: { red: 255, green: 255, blue: 255, alpha: 1 }
      },
      label: 'Weiter'
    },
    submitButton: {
      normalStyle: {
        background: {
          color: { red: 245, green: 78, blue: 103, alpha: 1 },
          image: undefined
        },
        font: undefined,
        borderColor: { red: 245, green: 78, blue: 103, alpha: 1 },
        borderWidth: 1,
        textColor: { red: 255, green: 255, blue: 255, alpha: 1 }
      },
      hoverStyle: {
        background: {
          color: { red: 247, green: 113, blue: 133, alpha: 1 },
          image: undefined
        },
        font: undefined,
        borderColor: { red: 247, green: 113, blue: 133, alpha: 1 },
        borderWidth: 0,
        textColor: { red: 255, green: 255, blue: 255, alpha: 1 }
      }
    },
    question: {
      background: {
        color: undefined,
        image: undefined
      },
      font: undefined,
      borderColor: undefined,
      borderWidth: 0,
      textColor: { red: 77, green: 77, blue: 77, alpha: 1 }
    },
    option: {
      background: {
        color: { red: 255, green: 255, blue: 255, alpha: 1 },
        image: undefined
      },
      font: undefined,
      borderColor: { red: 102, green: 152, blue: 197, alpha: 1 },
      borderWidth: 8,
      textColor: { red: 77, green: 77, blue: 77, alpha: 1 }
    },
    title: {
      background: {
        color: { red: 255, green: 255, blue: 255, alpha: 1 },
        image: undefined
      },
      font: undefined,
      borderColor: undefined,
      borderWidth: 0,
      textColor: { red: 102, green: 152, blue: 197, alpha: 1 }
    }
  },
  head: {
    logo: {
      image: {
        src:
          'https://res-4.cloudinary.com/chocobrain/image/upload/c_limit,f_svg,h_400,q_auto,w_auto/v1608224032/b4fsVavi/Logo%20Strahlenzentrum%20mit%20Text%20svg.svg',
        alt: 'Strahlenzentrum Hamburg MVZ Strahlentherapie Hamburg'
      },
      link: undefined
    },
    closeButton: {
      normalStyle: {
        background: {
          color: { red: 255, green: 255, blue: 255, alpha: 1 },
          image: undefined
        },
        font: undefined,
        borderColor: { red: 102, green: 152, blue: 197, alpha: 1 },
        borderWidth: 2,
        textColor: { red: 102, green: 152, blue: 197, alpha: 1 }
      },
      hoverStyle: {
        background: {
          color: { red: 102, green: 152, blue: 197, alpha: 1 },
          image: undefined
        },
        font: undefined,
        borderColor: { red: 102, green: 152, blue: 197, alpha: 1 },
        borderWidth: 2,
        textColor: { red: 255, green: 255, blue: 255, alpha: 1 }
      },
      label: '&times;'
    },
    background: {
      color: { red: 255, green: 255, blue: 255, alpha: 1 },
      image: undefined
    }
  },
  slides: [
    {
      position: 1,
      id: 'slide1',
      targetSlideId: 'slide2',
      titleContent: "Schreiben Sie uns!",
      titleHint: undefined,
      type: 'freeField',
      columns: 4,
      fields: [
        {
          id: 'request',
          required: false,
          label: 'Anliegen',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 1,
          columnSpan: 2,
          type: 'select',
          multiple: false,
          options: [
            {
              label: 'Terminanfrage',
              id: 'slide1Field1A',
              checked: true
            },
            {
              label: 'Rückruf',
              id: 'slide1Field1B',
              checked: false
            },
            {
              label: 'Frage zum CyberKnife',
              id: 'slide1Field1C',
              checked: false
            },
            {
              label: 'Frage zur Röntgenreizbestrahlung',
              id: 'slide1Field1D',
              checked: false
            },
            {
              label: 'Frage zur Strahlentherapie',
              id: 'slide1Field1E',
              checked: false
            },
            {
              label: 'Sonstiges',
              id: 'slide1Field1F',
              checked: false
            }
          ],
          formFieldName: 'Anliegen'
        },
        {
          id: 'desired_contact',
          required: false,
          label: 'Wunschkontaktaufnahme',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 2,
          columnSpan: 2,
          type: 'select',
          multiple: false,
          options: [
            {
              label: 'Telefonat',
              id: 'slide1Field1A',
              checked: true
            },
            {
              label: 'Videokonferenz',
              id: 'slide1Field1B',
              checked: false
            },
            {
              label: 'E-Mail',
              id: 'slide1Field1C',
              checked: false
            }
          ],
          formFieldName: 'Wunschkontaktaufnahme'
        },
        {
          id: 'message',
          required: false,
          label: 'Wir freuen uns auf Ihre Nachricht:',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 3,
          columnSpan: 2,
          type: 'textarea',
          value: undefined,
          cols: 140,
          rows: 10,
          placeholder: undefined,
          formFieldName: 'Nachricht'
        },
      ]
    },
    {
      position: 2,
      id: 'slide2',
      targetSlideId: 'slide3',
      titleContent: 'Möchten Sie zusätzlich Dateien hochladen?',
      titleHint: "(bspw. Röntgenbilder)",
      type: 'select',
      answerOptions: [
        {
          id: 'yes',
          label: 'Ja',
          hint: undefined,
          image: {
            src: 'https://res-4.cloudinary.com/chocobrain/image/upload/c_limit,f_svg,h_400,q_auto,w_auto/v1646306760/b4fsVavi/Upload%20Wolke',
            alt: undefined
          },
          selected: false,
          nextSlide: 'slide3'
        },
        {
          id: 'no',
          label: 'Nein',
          hint: undefined,
          image: {
            src: 'https://res-4.cloudinary.com/chocobrain/image/upload/c_limit,f_svg,h_400,q_auto,w_auto/v1646306734/b4fsVavi/No%20Upload%20Wolke',
            alt: undefined
          },
          selected: false,
          nextSlide: 'slide4'
        }
      ],
      multipleSelect: false,
      required: true,
      formFieldName: 'Datei_Upload_Nexus_Chili'
    },
    {
      position: 3,
      id: 'slide3',
      targetSlideId: 'slide5',
      titleContent: 'Bitte hinterlassen Sie hier Ihre Kontaktdaten',
      titleHint: undefined,
      type: 'freeField',
      columns: 4,
      fields: [
        {
          id: 'nameYes',
          required: true,
          label: 'Name',
          hint: undefined,
          autocomplete: 'name',
          positionColumn: 2,
          positionRow: 1,
          columnSpan: 2,
          type: 'input',
          inputType: 'text',
          value: undefined,
          maxlength: 24,
          minlength: 0,
          placeholder: undefined,
          size: undefined,
          formFieldName: 'Name'
        },
        {
          id: 'birthdayYes',
          required: true,
          label: 'Geburtsdatum',
          hint: undefined,
          autocomplete: 'bday',
          positionColumn: 2,
          positionRow: 2,
          columnSpan: 2,
          type: 'input',
          inputType: 'date',
          value: undefined,
          maxDate: undefined,
          minDate: undefined,
          formFieldName: 'Geburtsdatum'
        },
        {
          id: 'emailYes',
          required: false,
          label: 'E-Mail',
          hint: undefined,
          autocomplete: 'email',
          positionColumn: 2,
          positionRow: 3,
          columnSpan: 2,
          type: 'input',
          inputType: 'email',
          value: undefined,
          maxlength: 56,
          minlength: 6,
          multiple: false,
          placeholder: undefined,
          formFieldName: 'Mail',
          size: undefined
        },
        {
          id: 'phoneYes',
          required: true,
          label: 'Telefonnummer',
          hint: undefined,
          autocomplete: 'tel',
          positionColumn: 2,
          positionRow: 4,
          columnSpan: 2,
          type: 'input',
          inputType: 'tel',
          value: undefined,
          maxlength: 24,
          minlength: 0,
          placeholder: undefined,
          formFieldName: 'Telefonnummer',
          size: undefined
        },
        {
          id: 'privacyYes',
          required: true,
          label: ' ',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 5,
          columnSpan: 2,
          formFieldName: 'Einwilligung',
          type: "input",
          inputType: "checkbox",
          privacy: true,
          options: [
            {
              id: 'privacyOK',
              label: '<span>Einwilligung: Ich habe die <a href="/datenschutz" target="_blank" style="text-decoration: none;color: #f54e67;">Datenschutzerklärung</a>) zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an <a href="mailto:mail@szhh.de" style="color: #f54e67;">mail@szhh.de</a> zurückziehen.</span>',
              checked: false
            }
          ]
        },
        {
          id: 'submitYes',
          required: false,
          label: 'Absenden',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 5,
          columnSpan: 2,
          type: 'input',
          inputType: 'submit',
          formtarget: undefined,
          formFieldName: 'Absenden'
        }
      ]
    },
    {
      position: 3,
      id: 'slide4',
      targetSlideId: 'slide6',
      titleContent: 'Bitte hinterlassen Sie hier Ihre Kontaktdaten',
      titleHint: undefined,
      type: 'freeField',
      columns: 4,
      fields: [
        {
          id: 'nameNo',
          required: true,
          label: 'Name',
          hint: undefined,
          autocomplete: 'name',
          positionColumn: 2,
          positionRow: 1,
          columnSpan: 2,
          type: 'input',
          inputType: 'text',
          value: undefined,
          maxlength: 24,
          minlength: 0,
          placeholder: undefined,
          size: undefined,
          formFieldName: 'Name'
        },
        {
          id: 'birthdayNo',
          required: true,
          label: 'Geburtsdatum',
          hint: undefined,
          autocomplete: 'bday',
          positionColumn: 2,
          positionRow: 2,
          columnSpan: 2,
          type: 'input',
          inputType: 'date',
          value: undefined,
          minDate: undefined,
          maxDate: undefined,
          formFieldName: 'Geburtsdatum'
        },
        {
          id: 'emailNo',
          required: false,
          label: 'E-Mail',
          hint: undefined,
          autocomplete: 'email',
          positionColumn: 2,
          positionRow: 3,
          columnSpan: 2,
          type: 'input',
          inputType: 'email',
          value: undefined,
          maxlength: 56,
          minlength: 6,
          multiple: false,
          placeholder: undefined,
          formFieldName: 'Mail',
          size: undefined
        },
        {
          id: 'phoneNo',
          required: true,
          label: 'Telefonnummer',
          hint: undefined,
          autocomplete: 'tel',
          positionColumn: 2,
          positionRow: 4,
          columnSpan: 2,
          type: 'input',
          inputType: 'tel',
          value: undefined,
          maxlength: 24,
          minlength: 0,
          placeholder: undefined,
          formFieldName: 'Telefonnummer',
          size: undefined
        },
        {
          id: 'privacyNo',
          required: true,
          label: ' ',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 5,
          columnSpan: 2,
          formFieldName: 'Einwilligung',
          type: "input",
          inputType: "checkbox",
          privacy: true,
          options: [
            {
              id: 'privacyOK',
              label: '<span>Einwilligung: Ich habe die <a href="/datenschutz" target="_blank" style="text-decoration: none;color: #f54e67;">Datenschutzerklärung</a> zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und gespeichert werden. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an <a href="mailto:mail@szhh.de" style="color: #f54e67;">mail@szhh.de</a> zurückziehen.</span>',
              checked: false
            }
          ]
        },
        {
          id: 'submitNo',
          required: false,
          label: 'Absenden',
          hint: undefined,
          autocomplete: undefined,
          positionColumn: 2,
          positionRow: 5,
          columnSpan: 2,
          type: 'input',
          inputType: 'submit',
          formtarget: undefined,
          formFieldName: 'Absenden'
        }
      ]
    },
    {
      position: 4,
      id: 'slide5',
      targetSlideId: undefined,
      titleContent: 'Vielen Dank für Ihre Anfrage!',
      titleHint: '<div style="margin-top:1rem;margin-bottom:1rem;display:flex;justify-content:center;"><img src="https://res-4.cloudinary.com/chocobrain/image/upload/c_limit,f_svg,h_64,q_auto,w_64/v1646306760/b4fsVavi/Upload%20Wolke" class="cff-szhh-upload-svg"/>Laden Sie nun Ihre Daten hoch.</div><div style="position:relative; width:100%; height:576px;"><iframe style="position:absolute; left:0; top:0; width:100%; height:100%" src="https://upload.strahlenzentrum-hamburg.de/Uploader/?conf=upload" frameborder="0"></iframe></div><p>Nach dem Upload können Sie dieses Fenster über das „X“ am rechten oberen Bildschirmrand schließen.</p>',
      type: 'freeField',
      columns: 4,
      fields: []
    },
    {
      position: 4,
      id: 'slide6',
      targetSlideId: undefined,
      titleContent: 'Vielen Dank für Ihre Anfrage!',
      titleHint: '<p>Wir werden uns schnellstmöglich bei Ihnen melden.<br><br>Schauen Sie in der Zwischenzeit auch gerne auf unserem <a href="https://www.strahlenzentrum-hamburg.de/blog" target="_blank" style="color: #6698c5;">Blog</a> vorbei.</p><p>Nach dem Upload können Sie dieses Fenster über das „X“ am rechten oberen Bildschirmrand schließen.</p>',
      type: 'freeField',
      columns: 4,
      fields: []
    }
  ],
  footer: {
    logos: undefined,
    background: {
      color: { red: 255, green: 255, blue: 255, alpha: 1 },
      image: undefined
    },
    font: undefined
  }
}
