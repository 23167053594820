










































































import Vue from 'vue'
import { Store } from 'vuex'

import { rgbaConverter } from './helpers/helpers'

import SlideCard from './SlideCard.vue'
import SlideFreeFields from './SlideFreeFields.vue'

export default Vue.extend({
  name: 'Slide',
  components: { SlideCard, SlideFreeFields },
  props: {
    slideId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      defaultNextButton: true
    }
  },
  computed: {
    storeState(): IState {
      return (this.$store as Store<IState>).state
    },
    show(): boolean {
      return this.slideId === this.storeState.visibleSlideId
    },
    slideBackground(): string {
      return rgbaConverter(this.storeState.slidesConfig.slideBackground)
    },
    slide(): TSlideResult {
      return (this.$store as Store<IState>).getters.getSlideById(this.slideId)
    },
    isSelect(): boolean {
      return this.slide.type === 'select'
    },
    isField(): boolean {
      return this.slide.type === 'freeField'
    },
    modalIsOpen(): boolean {
      return this.storeState.showModal
    },
    hasRequired(): boolean {
      const slide = this.slide
      switch (slide.type) {
        case 'select':
          return slide.required
        case 'freeField':
          return slide.fields.some(field => field.required === true)
        default:
          throw new Error('Could not match slide type.')
      }
    },
    requiredText(): string {
      return '*Pflichtfeld'
    },
    titleColor(): string {
      return rgbaConverter(this.storeState.slidesConfig.question.textColor)
    },
    titleBackGroundColor(): string {
      return this.storeState.slidesConfig.question.background.color
        ? rgbaConverter(this.storeState.slidesConfig.question.background.color)
        : ''
    },
    slideHasHint(): boolean {
      return this.slide.titleHint !== undefined
    },
    requiredTitleContent(): string {
      return `${this.slide.titleContent}<span style="color:red">*</span>`
    },
    titleDOMClass(): string {
      return `${this.$store.state.clickFlowID}-slide-title`
    },
    hintDOMClass(): string {
      return `${this.$store.state.clickFlowID}-slide-hint`
    }
  }
})
