






















import Vue from 'vue'

export default Vue.extend({
  name: 'FreeFieldRadio',
  props: {
    field: {
      type: Object,
      required: true
    },
    slideId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      checkedOptionLabel: (this.field.options as IOption[]).find(
        option => option.checked === true
      )?.label
    }
  },
  computed: {
    fieldClass(): string {
      return `${this.$store.state.clickFlowID}-${this.field.id}`
    }
  },
  methods: {
    change() {
      this.$store.commit('commitRadioOption', {
        slideId: this.slideId,
        field: this.field,
        optionLabel: this.checkedOptionLabel
      })
    },
    optionId(optionId: string): string {
      return `${this.$store.state.clickFlowID}-${this.field.id}-${optionId}`
    }
  }
})
