













































import Vue from 'vue'

export default Vue.extend({
  name: 'FreeFieldTooltip',
  props: {
    field: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showTooltip: false,
      defaultStyle: true
    }
  },
  computed: {
    tooltipText(): string {
      return this.field.hint
    },
    fieldHasTooltip(): boolean {
      return this.field.hint !== undefined
    },
    tooltipPopupBackgroundColor(): string {
      return 'rgba(66,66,66,0.75)'
    },
    tooltipPopupTextColor(): string {
      return 'rgba(250,250,250,1)'
    },
    tooltipButtonBackgroundColor(): string {
      return this.defaultStyle ? 'rgba(99,99,99,1)' : 'rgba(150,150,150,1)'
    },
    tooltipButtonTextColor(): string {
      return this.defaultStyle ? 'rgba(250,250,250,1)' : 'rgba(250,250,250,1)'
    },
    fieldId(): string {
      return `${this.$store.state.clickFlowID}-tooltip-${this.field.id}`
    },
    fieldClass(): string {
      return `${this.$store.state.clickFlowID}-tooltip`
    },
    tooltipTextClass(): string {
      return `${this.$store.state.clickFlowID}-tooltip-text`
    }
  },
  methods: {
    hoverTooltip() {
      this.showTooltip = !this.showTooltip
    },
    tooltipButtonHover() {
      this.defaultStyle = !this.defaultStyle
    }
  }
})
