


































import Vue from 'vue'
import { Store } from 'vuex'
import { rgbaConverter } from './helpers/helpers'
import ProgressBar from './ProgressBar.vue'
import NextButton from './NextButton.vue'
import Slide from './Slide.vue'

export default Vue.extend({
  name: 'ModalBody',
  components: { ProgressBar, NextButton, Slide },
  computed: {
    store(): IState {
      return (this.$store as Store<IState>).state
    },
    visibleSlideId(): string {
      return this.store.visibleSlideId
    },
    visibleSlide(): TSlides {
      return (this.$store as Store<IState>).getters.getSlideById(
        this.visibleSlideId
      )
    },
    showNextButton(): boolean {
      if (this.visibleSlide.targetSlideId === undefined || this.isSubmitSlide) {
        return false
      }

      switch (this.visibleSlide.type) {
        case 'select':
          return this.visibleSlide.multipleSelect || this.showNextButtonOnSlide
        case 'freeField':
          return true
        default:
          throw new Error('Could not match visibleSide.type.')
      }
    },
    showNextButtonOnSlide(): boolean {
      return this.store.slidesConfig.nextButton.showOnSlide
    },
    bodyBackground(): string {
      return rgbaConverter(this.store.slidesConfig.slideBackground)
    },
    modalTitle(): string {
      return this.store.clickFlowTitle
    },
    modalHint(): string {
      return this.store.clickFlowDescription
        ? this.store.clickFlowDescription
        : ''
    },
    hasHint(): boolean {
      return this.modalHint !== ''
    },
    titleColor(): string {
      return rgbaConverter(this.store.slidesConfig.title.textColor)
    },
    isSubmitSlide(): boolean {
      return this.visibleSlide.type === 'freeField'
        ? this.visibleSlide.fields.some(
            field => field.type === 'input' && field.inputType === 'submit'
          )
        : false
    },
    storeState(): IState {
      return (this.$store as Store<IState>).state
    },
    slides(): ISlideConfig[] {
      return this.storeState.slides
    },
    titleDOMId(): string {
      return `${this.$store.state.clickFlowID}-modal-title`
    },
    hintDOMId(): string {
      return `${this.$store.state.clickFlowID}-modal-hint`
    },
    bodyDOMId(): string {
      return `${this.$store.state.clickFlowID}-body-content`
    }
  }
})
