export const validateSlide = (slide: TSlideResult): boolean => {
  const requiredFieldsHaveInput = unfilledRequiredFields(slide).length === 0
  const inputValid =
    slide.type === 'freeField' ? validateFreeFieldsSlide(slide) : true

  const allValidated = requiredFieldsHaveInput && inputValid
  return allValidated
}

export const unfilledRequiredFields = (slide: TSlideResult): string[] => {
  if (slide.type === 'select') {
    const cardSelected = slide.required
      ? slide.optionsResult.some(optionResult => optionResult.reply !== '')
      : true
    return !cardSelected ? [slide.titleContent] : []
  }
  if (slide.type === 'freeField') {
    const requiredFields = (slide.optionsResult as (TField & {
      reply: string
    })[]).filter(optionResult => optionResult.required)
    if (requiredFields.length === 0) return []

    return requiredFields
      .filter(
        requiredField =>
          (requiredField.type === 'input' &&
            requiredField.inputType === 'file' &&
            requiredField.reply === undefined) ||
          (requiredField.reply === '' &&
            (requiredField.type === 'input'
              ? requiredField.inputType !== 'submit'
              : true))
      )
      .map(unfilledField => unfilledField.label)
  }
  throw new Error(`Type of ${slide} doesn't exist.`)
}

const validateFreeFieldsSlide = (
  slide: IFreeFieldsSlide & {
    optionsResult: TOptionResult[]
  }
): boolean => {
  const optionsResult = slide.optionsResult as (TField & { reply: string })[]
  const toValidate = optionsResult.filter(
    optionResult =>
      optionResult.type === 'input' &&
      (optionResult.inputType === 'email' ||
        optionResult.inputType === 'number' ||
        optionResult.inputType === 'tel' ||
        optionResult.inputType === 'text' ||
        optionResult.inputType === 'range') &&
      optionResult.required
  ) as ((IEMail | INumber | ITel | IText | IRange) & { reply: string })[]

  const validateResult = toValidate
    .map(optionResult => {
      switch (optionResult.inputType) {
        case 'email':
          return (
            validateEmailInput(optionResult.reply) &&
            validateMinLength(optionResult, optionResult.reply) &&
            validateMaxLength(optionResult, optionResult.reply)
          )

        case 'number':
          return (
            validateMin(optionResult, optionResult.reply) &&
            validateMax(optionResult, optionResult.reply) &&
            validateStep(optionResult, optionResult.reply)
          )
        case 'tel':
          return (
            validateMinLength(optionResult, optionResult.reply) &&
            validateMaxLength(optionResult, optionResult.reply)
          )

        case 'text':
          return (
            validateMinLength(optionResult, optionResult.reply) &&
            validateMaxLength(optionResult, optionResult.reply) &&
            validatePattern(optionResult, optionResult.reply)
          )
        case 'range':
          return (
            validateMin(optionResult, optionResult.reply) &&
            validateMax(optionResult, optionResult.reply) &&
            validateStep(optionResult, optionResult.reply)
          )
        default:
          throw new Error('Could not match input type.')
      }
    })
    .filter(entry => entry === false)

  return validateResult.length === 0
}

export const validateEmailInput = (reply: string): boolean => {
  if (reply === '') {
    return true
  }
  const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return regEx.test(reply)
}

export const validateMinLength = (
  optionResult: (IEMail | ITel | IText) & { reply: string },
  reply: string
): boolean => {
  if (optionResult.minlength === undefined) {
    return true
  }
  const validateResult = reply.length >= optionResult.minlength
  return validateResult
}

export const validateMaxLength = (
  optionResult: (IEMail | ITel | IText) & { reply: string },
  reply: string
): boolean => {
  if (optionResult.maxlength === undefined) {
    return true
  }
  const validateResult = reply.length <= optionResult.maxlength
  return validateResult
}

export const validateMin = (
  optionResult: (INumber | IRange) & { reply: string },
  reply: string
): boolean => {
  if (optionResult.min === undefined || reply === '') {
    return true
  }
  const validateResult = Number.parseFloat(reply) >= optionResult.min
  return validateResult
}

export const validateMax = (
  optionResult: (INumber | IRange) & { reply: string },
  reply: string
): boolean => {
  if (optionResult.max === undefined || reply === '') {
    return true
  }
  const validateResult = Number.parseFloat(reply) <= optionResult.max
  return validateResult
}

export const validateStep = (
  optionResult: (INumber | IRange) & { reply: string },
  reply: string
): boolean => {
  if (optionResult.step === undefined || reply === '') {
    return true
  }
  const divideResult = Number.parseFloat(reply) / optionResult.step
  const roundResult = Number.parseFloat(divideResult.toPrecision(12)) * 1
  const validateResult = Number.isInteger(roundResult)
  return validateResult
}

export const validatePattern = (
  optionResult: IText & { reply: string },
  reply: string
): boolean => {
  if (optionResult.pattern === undefined) {
    return true
  }
  const validateResult = optionResult.pattern.test(reply)
  return validateResult
}

export const validateMinDate = (
  optionResult: IDate & { reply: string },
  reply: string
): boolean => {
  if (optionResult.minDate === undefined || reply === "") {
    return true
  }
  const validateResult = (new Date (reply)).getTime() >= (new Date (optionResult.minDate)).getTime()
  return validateResult
}

export const validateMaxDate = (
  optionResult: IDate & { reply: string },
  reply: string
): boolean => {
  if (optionResult.maxDate === undefined || reply === "") {
    return true
  }
  const validateResult = (new Date (reply)).getTime() <= (new Date (optionResult.maxDate)).getTime()
  return validateResult
}

