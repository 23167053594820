import Vue from 'vue'
import Vuex from 'vuex'

import { state, demoState } from './store/state'
import { mutations } from './store/mutations'
import { getters } from './store/getters'

import { demoMutations } from './store/demoMutations'
import { demoGetters } from './store/demoGetters'

Vue.use(Vuex)

export const store = new Vuex.Store({
  state,
  mutations,
  getters
})

export const demoStore = new Vuex.Store({
  state: demoState,
  mutations: demoMutations,
  getters: demoGetters
})
