import { MutationTree } from 'vuex'
import { demoStore } from '../store'
import Vue from 'vue'

export const demoMutations: MutationTree<IState> = {
  commitCard(
    _state,
    payload: {
      slideId: string
      answerOptionId: string
      selected: IAnswerOption['selected']
      reply: TOptionResult['reply']
    }
  ) {
    const answerOption = demoStore.getters.getAnswerOptionByIdAndSlideId(
      payload.answerOptionId,
      payload.slideId
    ) as IAnswerOption

    answerOption.selected = payload.selected

    const optionsResult = demoStore.getters.getOptionsResultByIdAndSlideId(
      payload.answerOptionId,
      payload.slideId
    ) as IAnswerOption & {
      reply: string
    }
    optionsResult.selected = payload.selected
    optionsResult.reply = payload.reply

    const keysAnswerOption = Object.keys(answerOption) as Array<
      keyof IAnswerOption
    >
    const keysOptionsResult = Object.keys(optionsResult) as Array<
      keyof (IAnswerOption & TOptionResult)
    >
    keysAnswerOption.map(key => Vue.set(answerOption, key, answerOption[key]))
    keysOptionsResult.map(key =>
      Vue.set(optionsResult, key, optionsResult[key])
    )
  },
  resetCards(
    _state,
    payload: {
      slideId: string
    }
  ) {
    const slide = demoStore.getters.getSlideById(payload.slideId)

    slide.answerOptions.map((answerOption: IAnswerOption) => {
      answerOption.selected = false

      const keys = Object.keys(answerOption) as Array<keyof IAnswerOption>

      return keys.map(key => Vue.set(answerOption, key, answerOption[key]))
    })

    slide.optionsResult.map((optionsResult: IAnswerOption & TOptionResult) => {
      optionsResult.selected = false
      optionsResult.reply = ''

      const keys = Object.keys(optionsResult) as Array<
        keyof (TOptionResult & IAnswerOption)
      >

      return keys.map(key => Vue.set(optionsResult, key, optionsResult[key]))
    })
  },
  visibleSlideId(
    state,
    payload: IAdditionalState['visibleSlideId']
  ): IAdditionalState['visibleSlideId'] {
    if (!payload)
      throw new Error(
        'Mutation `visibleSlideId` needs something to fill `visibleSlideId` in Store.'
      )

    return (state.visibleSlideId = payload)
  },
  commitCheckboxOption(
    state,
    payload: {
      slideId: IFreeFieldsSlide
      field: ICheckbox
      optionsId: IOption['id'][]
    }
  ) {
    if (!payload)
      throw new Error(
        'Mutation `commitCheckboxOption` needs something to fill `checked` in Store.'
      )
    const allOptions = (demoStore.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    ) as ICheckbox).options

    allOptions.map((option: IOption) => {
      option.checked = payload.optionsId.some(
        optionId => option.id === optionId
      )
      const keys = Object.keys(option) as Array<keyof IOption>

      return keys.map(key => Vue.set(option, key, option[key]))
    })
    const field = demoStore.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    )

    Vue.set(field, 'reply', payload.optionsId.join(', '))
  },
  commitSelectOption(
    state,
    payload: {
      slideId: IFreeFieldsSlide
      field: ISelectField
      optionId: IOption['id']
    }
  ) {
    if (!payload)
      throw new Error(
        'Mutation `commitSelectOption` needs something to fill `checked` in Store.'
      )
    const allOptions = (demoStore.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    ) as ISelectField).options

    allOptions.map((option: IOption) => {
      option.checked = option.id === payload.optionId

      const keys = Object.keys(option) as Array<keyof IOption>

      return keys.map(key => Vue.set(option, key, option[key]))
    })
    const field = demoStore.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    )

    Vue.set(field, 'reply', payload.optionId)
  },
  commitRadioOption(
    state,
    payload: {
      slideId: IFreeFieldsSlide
      field: IRadio
      optionId: IOption['id']
    }
  ) {
    if (!payload)
      throw new Error(
        'Mutation `commitRadioOption` needs something to fill `checked` in Store.'
      )
    const allOptions = (demoStore.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    ) as IRadio).options

    allOptions.map((option: IOption) => {
      option.checked = option.id === payload.optionId
      const keys = Object.keys(option) as Array<keyof IOption>

      return keys.map(key => Vue.set(option, key, option[key]))
    })
    const field = demoStore.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    )

    Vue.set(field, 'reply', payload.optionId)
  },
  commitFile(
    state,
    payload: {
      slideId: IFreeFieldsSlide
      field: IFile
      uploadedFile: File
      fileName: string
    }
  ) {
    if (!payload)
      throw new Error(
        'Mutation `commitFile` needs something to fill `value` in Store.'
      )
    const field = demoStore.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    ) as IFile

    Vue.set(field, 'reply', payload.uploadedFile)
    Vue.set(field, 'name', payload.fileName)
  },
  updateValue(
    state,
    payload: {
      slideId: IFreeFieldsSlide
      field: IField &
        (IEMail | INumber | ITel | IText | ITextareaField | IRange)
      fieldValue: (
        | IEMail
        | INumber
        | ITel
        | IText
        | ITextareaField
        | IRange
      )['value']
    }
  ) {
    if (!payload)
      throw new Error(
        'Mutation `updateValue` needs something to fill `reply` in Store.'
      )

    const field = demoStore.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    )

    Vue.set(field, 'reply', payload.fieldValue)
  }
}
