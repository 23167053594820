



















import Vue from 'vue'
import { Store } from 'vuex'

import {
  validateMin,
  validateMax,
  validateStep
} from './helpers/validateHelpers'
export default Vue.extend({
  name: 'FreeFieldRange',
  props: {
    field: {
      type: Object,
      required: true
    },
    slideId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      rangeValue: this.field.value ? this.field.value : this.field.max / 2,
      validated: true
    }
  },
  computed: {
    fieldDOMId(): string {
      return `${this.$store.state.clickFlowID}-range-${this.field.id}`
    },
    validateError(): string {
      return !this.validated ? 'cfa-free-field-range-validate-error' : ''
    },
    optionResult(): IRange & {
      reply: string
    } {
      return this.$store.getters.getOptionsResultByFieldIdAndSlideId(
        this.field.id,
        this.slideId
      )
    }
  },
  methods: {
    updateValue() {
      if (
        validateMin(this.optionResult, this.rangeValue) &&
        validateMax(this.optionResult, this.rangeValue) &&
        validateStep(this.optionResult, this.rangeValue)
      ) {
        this.validated = true
        this.$store.commit('updateValue', {
          slideId: this.slideId,
          field: this.field,
          fieldValue: this.rangeValue
        })
      } else {
        this.validated = false
        this.$store.commit('updateValue', {
          slideId: this.slideId,
          field: this.field,
          fieldValue: ''
        })
      }
    }
  }
})
