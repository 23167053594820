import { findInitialVisibleSlideId } from '../../store/state'
import { store, demoStore } from '../../store'

export const rgbaConverter = (color: IColor | undefined): string => {
  return color === undefined ? `rgba(0,0,0,0.5)` : `rgba(${color.red},${color.green},${color.blue},${color.alpha})`
}

let bodyOverFlowY = 'auto'
let bodyWidth = ''
let scrollPosition = 0

export const openModal = (): void => {
  scrollPosition = window.pageYOffset
  bodyOverFlowY = document.body.style.overflowY
  bodyWidth = document.body.style.width

  store.state.showModal = true
  document.body.style.position = 'fixed'
  document.body.style.width = '100%'
  document.body.style.overflowY = 'hidden'
}
export const closeModal = (): void => {
  store.commit('resetSlides')
  store.state.showModal = false
  store.state.visibleSlideId = findInitialVisibleSlideId()
  document.body.style.overflowY = bodyOverFlowY
  document.body.style.width = bodyWidth
  document.body.style.removeProperty('position')
  window.scrollTo(0, scrollPosition)
}

let demoOverflowY = 'auto'
export const demoOpenModal = (): void => {
  demoStore.state.showModal = true
  demoOverflowY = document.body.style.overflowY
  document.body.style.overflowY = 'hidden'
  document.body.style.position = 'fixed'
  document.body.style.width = '100%'
  demoStore.state.openModalCallback('Modal opened')
}
export const demoCloseModal = (): void => {
  demoStore.state.showModal = false
  document.body.style.overflowY = demoOverflowY
  document.body.style.removeProperty('position')
  demoStore.state.openModalCallback('Modal closed')
}
