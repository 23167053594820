



















import Vue from 'vue'
import { Store } from 'vuex'

import { rgbaConverter, openModal } from './helpers/helpers'
import {
  unfilledRequiredFields,
  validateSlide
} from './helpers/validateHelpers'

export default Vue.extend({
  name: 'NextButton',
  props: {
    slideId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      defaultNextButton: true
    }
  },
  computed: {
    storeState(): IState {
      return (this.$store as Store<IState>).state
    },
    slide(): TSlideResult {
      return (this.$store as Store<IState>).getters.getSlideById(this.slideId)
    },
    isSelect(): boolean {
      return this.slide.type === 'select'
    },
    isField(): boolean {
      return this.slide.type === 'freeField'
    },
    nextButtonNormal(): IStyle {
      return this.storeState.slidesConfig.nextButton.normalStyle
    },
    buttonBackground(): string {
      if (!validateSlide(this.slide)) {
        return '#DDD'
      }
      return this.defaultNextButton
        ? rgbaConverter(this.nextButtonNormal.background.color)
        : rgbaConverter(
            this.storeState.slidesConfig.nextButton.hoverStyle.background.color
          )
    },
    buttonTextColor(): string {
      if (!validateSlide(this.slide)) {
        return '#999'
      }
      return rgbaConverter(this.nextButtonNormal.textColor)
    },
    buttonBorder(): string {
      return `${this.nextButtonNormal.borderWidth}px solid ${rgbaConverter(
        this.nextButtonNormal.borderColor
      )}`
    },
    modalIsOpen(): boolean {
      return this.storeState.showModal
    },
    disOrEnabledButton(): string {
      return validateSlide(this.slide)
        ? 'cfa-slide-next-button-enabled'
        : 'cfa-slide-next-button-disabled'
    },
    nextButtonText(): string {
      return this.storeState.slidesConfig.nextButton.label
    },
    nextButtonId(): string {
      return `${this.$store.state.clickFlowID}-next-button`
    }
  },
  methods: {
    click() {
      // Needed to disable Button
      if (validateSlide(this.slide)) {
        // Needed for modal is opened by first Slide instead of button.
        if (!this.storeState.showModal) {
          openModal()
        }
        this.nextSlide()
      } else {
        const fieldsList = unfilledRequiredFields(this.slide).map(
          unfilledRequiredField => '\n - ' + unfilledRequiredField
        )
        alert(`Bitte füllen Sie folgende Pflichtfelder aus:${fieldsList}`)
      }
    },
    nextButtonHover() {
      if (validateSlide(this.slide))
        this.defaultNextButton = !this.defaultNextButton
    },
    nextSlide() {
      let nextSlideId = this.slide.targetSlideId
      if (this.slide.type === 'select' && !this.slide.multipleSelect) {
        const selectedOption = (this.slide.optionsResult as (IAnswerOption & {
          reply: string
        })[]).find(optionResult => optionResult.reply !== '')
        if (selectedOption !== undefined) {
          if (selectedOption.nextSlide) {
            nextSlideId = selectedOption.nextSlide
          }
        }
      }
      this.$store.commit('visibleSlideId', nextSlideId)
    }
  }
})
