import { configToEmptyResult } from '../configToEmptyResult'
import { validatedConfig, validatedDemoConfig } from '../config'

if (validatedConfig.error)
  throw new TypeError(
    `state: CFA aborted because Config Data was invalid! ${validatedConfig.error.message}`
  )
const userConfig: IResult = configToEmptyResult(validatedConfig.value)
const demoConfig: IResult = configToEmptyResult(validatedDemoConfig.value)

export const findInitialVisibleSlideId = (): TSlides['id'] => {
  const lowestPosition = Math.min(
    ...userConfig.slides.map(slide => slide.position)
  )
  const firstSlide = userConfig.slides.find(
    slide => slide.position === lowestPosition
  )
  if (!firstSlide)
    throw new Error(`Can not find Slide with positon ${lowestPosition}!`)

  return firstSlide.id
}

const additionalState: IAdditionalState = {
  visibleSlideId: findInitialVisibleSlideId(),
  showModal: false
}

export const state: IState = { ...userConfig, ...additionalState }
export const demoState: IState = { ...demoConfig, ...additionalState }
