


















import Vue from 'vue'
import { validateMinLength, validateMaxLength } from './helpers/validateHelpers'

export default Vue.extend({
  name: 'FreeFieldTel',
  props: {
    field: {
      type: Object,
      required: true
    },
    slideId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      telValue: '',
      validated: true
    }
  },
  computed: {
    fieldDOMId(): string {
      return `${this.$store.state.clickFlowID}-tel-${this.field.id}`
    },
    validateError(): string {
      return !this.validated ? 'cfa-free-field-tel-validate-error' : ''
    },
    optionResult(): IEMail & {
      reply: string
    } {
      return this.$store.getters.getOptionsResultByFieldIdAndSlideId(
        this.field.id,
        this.slideId
      )
    }
  },
  methods: {
    updateValue() {
      if (
        validateMinLength(this.optionResult, this.telValue) &&
        validateMaxLength(this.optionResult, this.telValue)
      ) {
        this.validated = true
        this.$store.commit('updateValue', {
          slideId: this.slideId,
          field: this.field,
          fieldValue: this.telValue
        })
      } else {
        this.validated = false
        if (this.telValue === '') {
          this.validated = true
        }
        this.$store.commit('updateValue', {
          slideId: this.slideId,
          field: this.field,
          fieldValue: ''
        })
      }
    }
  }
})
