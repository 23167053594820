













import Vue from 'vue'
import { Store } from 'vuex'

import FreeFields from './FreeFields.vue'

export default Vue.extend({
  name: 'SlideFreeFields',
  components: { FreeFields },
  props: {
    slideId: {
      type: String,
      required: true
    }
  },
  computed: {
    storeState(): IState {
      return (this.$store as Store<IState>).state
    },
    slide(): IFreeFieldsSlide {
      return (this.$store as Store<IState>).getters.getSlideById(this.slideId)
    },
    columns(): number {
      return this.slide.columns
    },
    columnClass(): string {
      return `cfa-slide-free-fields-column-${this.columns}`
    }
  }
})
