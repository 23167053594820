import joi from 'joi'

export const validate = (
  config: Readonly<IUserConfig>
): Readonly<joi.ValidationResult> => {
  const schema = joi
    .object()
    .required()
    .keys({
      openModalCallback: joi.function().arity(1),
      clickFlowFinishedCallback: joi.function().arity(1),
      openModalByCards: joi.boolean().required(),
      clickFlowID: joi
        .string()
        .required()
        .pattern(/^[a-zA-Z0-9-_]+$/),
      clickFlowTitle: joi.string(),
      clickFlowDescription: joi.string(),
      versionClickFlow: joi
        .number()
        .integer()
        .greater(0)
        .required(),
      pathnameBackupForm: joi.string().pattern(/^\/[a-z0-9-\/.]+/),
      fontSource: joi.string(),
      slidesConfig: joi.object({
        slideBackground: joi.object({
          red: joi
            .number()
            .integer()
            .min(0)
            .max(255),
          green: joi
            .number()
            .integer()
            .min(0)
            .max(255),
          blue: joi
            .number()
            .integer()
            .min(0)
            .max(255),
          alpha: joi
            .number()
            .precision(2)
            .min(0)
            .max(1)
        }),
        nextButton: joi.object({
          normalStyle: joi.object({
            background: joi.object({
              color: joi.object({
                red: joi
                  .number()
                  .integer()
                  .min(0)
                  .max(255),
                green: joi
                  .number()
                  .integer()
                  .min(0)
                  .max(255),
                blue: joi
                  .number()
                  .integer()
                  .min(0)
                  .max(255),
                alpha: joi
                  .number()
                  .precision(2)
                  .min(0)
                  .max(1)
              }),
              image: joi.object({
                src: joi.string(),
                alt: joi.string()
              })
            }),
            font: joi.object({
              family: joi.string(),
              weight: joi
                .number()
                .integer()
                .multiple(100)
                .min(100)
                .max(900),
              size: joi.number().integer()
            }),
            borderColor: joi.object({
              red: joi
                .number()
                .integer()
                .min(0)
                .max(255),
              green: joi
                .number()
                .integer()
                .min(0)
                .max(255),
              blue: joi
                .number()
                .integer()
                .min(0)
                .max(255),
              alpha: joi
                .number()
                .precision(2)
                .min(0)
                .max(1)
            }),
            borderWidth: joi
              .number()
              .integer()
              .min(0),
            textColor: joi.object({
              red: joi
                .number()
                .integer()
                .min(0)
                .max(255),
              green: joi
                .number()
                .integer()
                .min(0)
                .max(255),
              blue: joi
                .number()
                .integer()
                .min(0)
                .max(255),
              alpha: joi
                .number()
                .precision(2)
                .min(0)
                .max(1)
            })
          }),
          hoverStyle: joi.link('normalStyle'),
          label: joi.string().required(),
          showOnSlide: joi.boolean(),
          disableWithoutInput: joi.boolean()
        }),
        submitButton: joi.object({
          normalStyle: joi.object({
            background: joi.object({
              color: joi.object({
                red: joi
                  .number()
                  .integer()
                  .min(0)
                  .max(255),
                green: joi
                  .number()
                  .integer()
                  .min(0)
                  .max(255),
                blue: joi
                  .number()
                  .integer()
                  .min(0)
                  .max(255),
                alpha: joi
                  .number()
                  .precision(2)
                  .min(0)
                  .max(1)
              }),
              image: joi.object({
                src: joi.string(),
                alt: joi.string()
              })
            }),
            font: joi.object({
              family: joi.string(),
              weight: joi
                .number()
                .integer()
                .multiple(100)
                .min(100)
                .max(900),
              size: joi.number().integer()
            }),
            borderColor: joi.object({
              red: joi
                .number()
                .integer()
                .min(0)
                .max(255),
              green: joi
                .number()
                .integer()
                .min(0)
                .max(255),
              blue: joi
                .number()
                .integer()
                .min(0)
                .max(255),
              alpha: joi
                .number()
                .precision(2)
                .min(0)
                .max(1)
            }),
            borderWidth: joi
              .number()
              .integer()
              .min(0),
            textColor: joi.object({
              red: joi
                .number()
                .integer()
                .min(0)
                .max(255),
              green: joi
                .number()
                .integer()
                .min(0)
                .max(255),
              blue: joi
                .number()
                .integer()
                .min(0)
                .max(255),
              alpha: joi
                .number()
                .precision(2)
                .min(0)
                .max(1)
            })
          }),
          hoverStyle: joi.link('normalStyle')
        }),
        question: joi.link('/slidesConfig.submitButton.normalStyle'),
        option: joi.link('/slidesConfig.submitButton.normalStyle'),
        title: joi.link('/slidesConfig.submitButton.normalStyle')
      }),
      head: joi.object({
        logo: joi.object({
          image: joi.object({
            src: joi.string(),
            alt: joi.string()
          }),
          link: joi.object({
            src: joi.string(),
            title: joi.string(),
            target: joi.string()
          })
        }),
        closeButton: joi.object({
          normalStyle: joi.link('/slidesConfig.submitButton.normalStyle'),
          hoverStyle: joi.link('/slidesConfig.submitButton.normalStyle'),
          label: joi.string().required()
        }),
        background: joi.object({
          color: joi.object({
            red: joi
              .number()
              .integer()
              .min(0)
              .max(255),
            green: joi
              .number()
              .integer()
              .min(0)
              .max(255),
            blue: joi
              .number()
              .integer()
              .min(0)
              .max(255),
            alpha: joi
              .number()
              .precision(2)
              .min(0)
              .max(1)
          }),
          image: joi.object({
            src: joi.string(),
            alt: joi.string()
          })
        })
      }),
      slides: joi
        .array()
        .unique((a, b) => a.id === b.id)
        .items({
          position: joi.number().integer(),
          id: joi
            .string()
            .pattern(/^[a-zA-Z0-9-_]+$/)
            .required(),
          targetSlideId: joi.string().pattern(/^[a-zA-Z0-9-_]+$/),
          titleContent: joi.string(),
          titleHint: joi.string(),
          type: joi
            .string()
            .pattern(/select|freeField/)
            .min(6)
            .max(10)
            .required(),
          answerOptions: joi
            .array()
            .unique((a, b) => a.id === b.id || a.label === b.label)
            .items({
              id: joi
                .string()
                .required()
                .pattern(/^[a-zA-Z0-9-_]+$/),
              label: joi.string().required(),
              hint: joi.string(),
              image: joi.object({
                src: joi.string(),
                alt: joi.string()
              }),
              selected: joi.boolean(),
              nextSlide: joi.string()
            }),
          multipleSelect: joi.boolean(),
          required: joi.boolean(),
          formFieldName: joi.string(),
          fields: joi
            .array()
            .unique((a, b) => a.id === b.id)
            .items({
              id: joi
                .string()
                .pattern(/^[a-zA-Z0-9-_]+$/)
                .required(),
              required: joi.boolean().required(),
              label: joi.string(),
              hint: joi.string(),
              autocomplete: joi
                .string()
                .pattern(
                  /mobilephone|name|honorific-prefix|given-name|additional-name|family-name|honorific-suffix|nickname|username|new-password|current-password|one-time-code|organization-title|organization|street-address|address-line1|address-line2|address-line3|address-level4|address-level3|address-level2|address-level1|country|country-name|postal-code|cc-name|cc-given-name|cc-additional-name|cc-family-name|cc-number|cc-exp|cc-exp-month|cc-exp-year|cc-csc|cc-type|transaction-currency|transaction-amount|language|bday|bday-day|bday-month|bday-year|sex|url|photo|email|tel/
                ),
              positionColumn: joi
                .number()
                .integer()
                .min(1)
                .max(6)
                .required(),
              positionRow: joi
                .number()
                .integer()
                .min(1)
                .required(),
              columnSpan: joi
                .number()
                .integer()
                .max(6),
              type: joi.string().pattern(/select|textarea|input/),
              formFieldName: joi.string().required(),
              multiple: joi.boolean(),
              size: joi.number().integer(),
              cols: joi.number(),
              rows: joi.number(),
              placeholder: joi.string(),
              inputType: joi
                .string()
                .pattern(
                  /email|range|tel|text|number|file|submit|checkbox|radio|date/
                ),
              value: joi.string(),
              maxlength: joi.number(),
              minlength: joi.number(),
              max: joi.number(),
              min: joi.number(),
              maxDate: joi.string().pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/),
              minDate: joi.string().pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/),
              pattern: joi.object().regex(),
              step: joi.number(),
              accept: joi.string(),
              capture: joi.string(),
              formtarget: joi.string(),
              name: joi.string(),
              base: joi.string(),
              options: joi
                .array()
                .unique((a, b) => a.id === b.id)
                .items({
                  label: joi.string(),
                  id: joi.string().pattern(/^[a-zA-Z0-9-_]+$/),
                  checked: joi.boolean()
                }),
              privacy: joi.boolean()
            }),
          columns: joi
            .number()
            .integer()
            .min(1)
            .max(6)
        }),
      footer: joi.object({
        logos: joi.array().items({
          image: joi.object({
            src: joi.string(),
            alt: joi.string()
          }),
          link: joi.object({
            src: joi.string(),
            title: joi.string(),
            target: joi.string()
          })
        }),
        background: joi.link('/head.background'),
        font: joi.link('/slidesConfig.submitButton.normalStyle.font')
      })
    })
  return Object.freeze(schema.validate(config))
}
