



















import Vue from 'vue'
import {
  validateMinLength,
  validateMaxLength,
  validatePattern
} from './helpers/validateHelpers'

export default Vue.extend({
  name: 'FreeFieldText',
  props: {
    field: {
      type: Object,
      required: true
    },
    slideId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      textValue: '',
      validated: true
    }
  },
  computed: {
    fieldDOMId(): string {
      return `${this.$store.state.clickFlowID}-text-${this.field.id}`
    },
    slide(): TSlides {
      return this.$store.getters.getSlideById(this.slideId)
    },
    validateError(): string {
      return !this.validated ? 'cfa-free-field-text-validate-error' : ''
    },
    optionResult(): IText & {
      reply: string
    } {
      switch (this.slide.type) {
        case 'select':
          return this.$store.getters.getOptionsResultByIdAndSlideId(
            this.field.id,
            this.slideId
          )
        case 'freeField':
          return this.$store.getters.getOptionsResultByFieldIdAndSlideId(
            this.field.id,
            this.slideId
          )
        default:
          throw new Error('Could not match slide type.')
      }
    }
  },
  methods: {
    updateValue() {
      if (
        validateMinLength(this.optionResult, this.textValue) &&
        validateMaxLength(this.optionResult, this.textValue) &&
        validatePattern(this.optionResult, this.textValue)
      ) {
        this.validated = true
        this.slide.type === 'freeField'
          ? this.$store.commit('updateValue', {
              slideId: this.slideId,
              field: this.field,
              fieldValue: this.textValue
            })
          : // Needed if Field is inside Card
          this.textValue
          ? this.$store.commit('commitCard', {
              slideId: this.slideId,
              answerOptionId: this.field.id,
              selected: true,
              reply: this.textValue
            })
          : this.$store.commit('resetCards', {
              slideId: this.slideId
            })
      } else {
        this.validated = false
        if (this.textValue === '') {
          this.validated = true
        }
        this.$store.commit('updateValue', {
          slideId: this.slideId,
          field: this.field,
          fieldValue: ''
        })
      }
    }
  }
})
