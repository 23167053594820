































































































import Vue from 'vue'
import { Store } from 'vuex'

import FreeFieldCheckbox from './FreeFieldCheckbox.vue'
import FreeFieldEmail from './FreeFieldEmail.vue'
import FreeFieldFile from './FreeFieldFile.vue'
import FreeFieldNumber from './FreeFieldNumber.vue'
import FreeFieldRadio from './FreeFieldRadio.vue'
import FreeFieldSelect from './FreeFieldSelect.vue'
import FreeFieldSubmit from './FreeFieldSubmit.vue'
import FreeFieldTel from './FreeFieldTel.vue'
import FreeFieldText from './FreeFieldText.vue'
import FreeFieldTextarea from './FreeFieldTextarea.vue'
import FreeFieldTooltip from './FreeFieldTooltip.vue'
import FreeFieldRange from './FreeFieldRange.vue'
import FreeFieldDate from './FreeFieldDate.vue'
import FreeFieldsLabel from './FreeFieldsLabel.vue'

export default Vue.extend({
  name: 'FreeFields',
  components: {
    FreeFieldCheckbox,
    FreeFieldEmail,
    FreeFieldFile,
    FreeFieldNumber,
    FreeFieldRadio,
    FreeFieldSelect,
    FreeFieldSubmit,
    FreeFieldTel,
    FreeFieldText,
    FreeFieldTextarea,
    FreeFieldTooltip,
    FreeFieldRange,
    FreeFieldsLabel,
    FreeFieldDate
  },
  props: {
    field: {
      type: Object,
      required: true
    },
    slideId: {
      type: String,
      required: true
    }
  },
  computed: {
    storeState(): IState {
      return (this.$store as Store<IState>).state
    },
    fieldPosition(): string {
      return `column-start-${this.field.positionColumn} column-span-${this.field.columnSpan}`
    },
    isRequired(): boolean {
      return this.field.required
    },
    cfaID(): string {
      return this.storeState.clickFlowID
    },
    submitField(): boolean {
      return this.field.inputType === 'submit'
    },
    fieldsWrapperDOMId(): string {
      return `${this.storeState.clickFlowID}-fields-wrapper`
    }
  },
  methods: {
    showField(inputType: string): boolean {
      return this.field.inputType === inputType || this.field.type === inputType
    }
  }
})
