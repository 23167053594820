import { MutationTree } from 'vuex'
import { store } from '../store'
import Vue from 'vue'

export const mutations: MutationTree<IState> = {
  commitCard(
    _state,
    payload: {
      slideId: string
      answerOptionId: string
      selected: IAnswerOption['selected']
      reply: TOptionResult['reply']
    }
  ) {
    const optionsResult = store.getters.getOptionsResultByIdAndSlideId(
      payload.answerOptionId,
      payload.slideId
    ) as IAnswerOption & {
      reply: string
    }
    optionsResult.selected = payload.selected
    optionsResult.reply = payload.reply

    const keysOptionsResult = Object.keys(optionsResult) as Array<
      keyof (IAnswerOption & TOptionResult)
    >
    keysOptionsResult.map(key =>
      Vue.set(optionsResult, key, optionsResult[key])
    )
  },
  resetCards(
    _state,
    payload: {
      slideId: string
    }
  ) {
    const slide = store.getters.getSlideById(payload.slideId)

    slide.answerOptions.map((answerOption: IAnswerOption) => {
      answerOption.selected = false

      const keys = Object.keys(answerOption) as Array<keyof IAnswerOption>

      return keys.map(key => Vue.set(answerOption, key, answerOption[key]))
    })

    slide.optionsResult.map((optionResult: IAnswerOption & TOptionResult) => {
      optionResult.selected = false
      optionResult.reply = ''

      const keys = Object.keys(optionResult) as Array<
        keyof (TOptionResult & IAnswerOption)
      >

      return keys.map(key => Vue.set(optionResult, key, optionResult[key]))
    })
  },
  visibleSlideId(
    state,
    payload: IAdditionalState['visibleSlideId']
  ): IAdditionalState['visibleSlideId'] {
    if (!payload)
      throw new Error(
        'Mutation `visibleSlideId` needs something to fill `visibleSlideId` in Store.'
      )

    return (state.visibleSlideId = payload)
  },
  commitCheckboxOption(
    _state,
    payload: {
      slideId: IFreeFieldsSlide
      field: ICheckbox
      optionsLabel: IOption['label'][]
    }
  ) {
    if (!payload)
      throw new Error(
        'Mutation `commitCheckboxOption` needs something to fill `checked` in Store.'
      )
    const allOptions = (store.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    ) as ICheckbox).options

    allOptions.map((option: IOption) => {
      option.checked = payload.optionsLabel.some(
        optionLabel => option.label === optionLabel
      )
      const keys = Object.keys(option) as Array<keyof IOption>

      return keys.map(key => Vue.set(option, key, option[key]))
    })
    const field = store.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    )

    Vue.set(field, 'reply', payload.optionsLabel.join(', '))
  },
  commitSelectOption(
    _state,
    payload: {
      slideId: IFreeFieldsSlide
      field: ISelectField
      optionLabel: IOption['label']
    }
  ) {
    if (!payload)
      throw new Error(
        'Mutation `commitSelectOption` needs something to fill `checked` in Store.'
      )
    const allOptions = (store.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    ) as ISelectField).options

    allOptions.map((option: IOption) => {
      option.checked = option.id === payload.optionLabel

      const keys = Object.keys(option) as Array<keyof IOption>

      return keys.map(key => Vue.set(option, key, option[key]))
    })
    const field = store.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    )

    Vue.set(field, 'reply', payload.optionLabel)
  },
  commitRadioOption(
    _state,
    payload: {
      slideId: IFreeFieldsSlide['id']
      field: IRadio
      optionLabel: IOption['label']
    }
  ) {
    if (!payload)
      throw new Error(
        'Mutation `commitRadioOption` needs something to fill `checked` in Store.'
      )
    const allOptions = (store.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    ) as IRadio).options

    allOptions.map((option: IOption) => {
      option.checked = option.id === payload.optionLabel
      const keys = Object.keys(option) as Array<keyof IOption>

      return keys.map(key => Vue.set(option, key, option[key]))
    })
    const field = store.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    )

    Vue.set(field, 'reply', payload.optionLabel)
  },
  commitFile(
    _state,
    payload: {
      slideId: IFreeFieldsSlide
      field: IFile
      uploadedFile: File
      fileName: string
    }
  ) {
    if (!payload)
      throw new Error(
        'Mutation `commitFile` needs something to fill `value` in Store.'
      )
    const field = store.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    ) as IFile

    Vue.set(field, 'reply', payload.uploadedFile)
    Vue.set(field, 'name', payload.fileName)
  },
  updateValue(
    _state,
    payload: {
      slideId: IFreeFieldsSlide
      field: TField &
        (IEMail | INumber | ITel | IText | ITextareaField | IRange)
      fieldValue: (
        | IEMail
        | INumber
        | ITel
        | IText
        | ITextareaField
        | IRange
      )['value']
    }
  ) {
    if (!payload)
      throw new Error(
        'Mutation `updateValue` needs something to fill `reply` in Store.'
      )

    const field = store.getters.getOptionsResultByFieldIdAndSlideId(
      payload.field.id,
      payload.slideId
    )

    Vue.set(field, 'reply', payload.fieldValue)
  },
  resetSlides(_state, _payload) {
    const slides = store.state.slides
    const freeFieldSlides = slides.filter(slide => slide.type === 'freeField')

    slides.map(slide => {
      if (slide.type === 'select') {
        const selectOptionsResult = slide.optionsResult as (IAnswerOption & {
          reply: string
        })[]
        selectOptionsResult.map(optionResult => {
          ;(optionResult as IAnswerOption & {
            reply: string
          }).selected = false
          optionResult.reply = ''

          const keys = Object.keys(optionResult) as Array<
            keyof (IAnswerOption & { reply: string })
          >

          return keys.map(key => Vue.set(optionResult, key, optionResult[key]))
        })
      } else if (slide.type === 'freeField') {
        const freeFieldOptionsResult = slide.optionsResult as (TField & {
          reply: string
        })[]
        freeFieldOptionsResult.map(optionResult => {
          optionResult.reply = ''

          const keys = Object.keys(optionResult) as Array<
            keyof (TField & { reply: string })
          >
          if (
            optionResult.type === 'input' &&
            (optionResult.inputType === 'radio' ||
              optionResult.inputType === 'checkbox')
          ) {
            store.commit('resetCheckboxRadioOption', {
              slideId: slide.id,
              fieldId: optionResult.id
            })
          }

          return keys.map(key => Vue.set(optionResult, key, optionResult[key]))
        })
      } else {
        throw new Error('Could not match slide type.')
      }
    })
  },
  resetCheckboxRadioOption(
    _state,
    payload: {
      slideId: IFreeFieldsSlide['id']
      fieldId: IRadio['id'] | ICheckbox['id']
    }
  ) {
    if (!payload)
      throw new Error(
        'Mutation `resetCheckboxRadioOption` needs something to fill `checked` in Store.'
      )
    const allOptions = (store.getters.getOptionsResultByFieldIdAndSlideId(
      payload.fieldId,
      payload.slideId
    ) as IRadio).options

    allOptions.map((option: IOption) => {
      option.checked = false
      const keys = Object.keys(option) as Array<keyof IOption>

      return keys.map(key => Vue.set(option, key, option[key]))
    })
  }
}
