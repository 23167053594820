























import Vue from 'vue'

export default Vue.extend({
  name: 'FreeFieldFile',
  props: {
    field: {
      type: Object,
      required: true
    },
    slideId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      uploadedFile: '' as string | ArrayBuffer | null,
      selectFile: true,
      fileName: ''
    }
  },
  computed: {
    fieldId(): string {
      return `${this.$store.state.clickFlowID}-file-${this.field.id}`
    }
  },
  methods: {
    commitFile(e: Event) {
      const target = e.target as HTMLInputElement
      const files = target.files
      if (!(files instanceof FileList))
        throw new Error('Files are not a FileList!')
      if (files.length !== 1) throw new Error('Not exactly one file found!')

      const file = files[0]
      if (file.name === '') throw new Error('No File Name found!')
      if (file.size === 0) throw new Error('Empty File found!')

      this.$store.commit('commitFile', {
        slideId: this.slideId,
        field: this.field,
        uploadedFile: file,
        fileName: file.name
      })
      this.selectFile = false
      let fileSize = ''
      if (file.size < 1000) {
        fileSize = file.size + 'Bytes'
      } else if (file.size >= 1000 && file.size <= 999999) {
        fileSize = (file.size / 1000).toFixed(2) + 'KB'
      } else {
        fileSize = (file.size / 1000000).toFixed(2) + 'MB'
      }
      this.fileName = `${file.name}, ${fileSize.replace('.', ',')}`
    },
    removeFile() {
      this.uploadedFile = ''
      this.fileName = ''
      this.selectFile = true
      this.$store.commit('commitFile', {
        slideId: this.slideId,
        field: this.field,
        uploadedFile: this.uploadedFile,
        fileName: this.fileName
      })
    }
  }
})
