import { GetterTree } from 'vuex'

export const demoGetters: GetterTree<IResult, IResult> = {
  getSlideById: demoState => (id: TSlides['id']) => {
    const slide = demoState.slides.find(slide => slide.id === id)
    if (slide === undefined)
      throw new Error(`getSlide: Slide with ID ${id} not found!`)

    return slide
  },
  getAnswerOptionByIdAndSlideId: demoState => (
    id: IAnswerOption['id'],
    slideId: ISelectSlide['id']
  ) => {
    const slide = demoState.slides.find(slide => slide.id === slideId)
    if (slide === undefined)
      throw new Error(`getAnswerOption: Slide with ID ${slideId} not found!`)
    if (slide.type === 'freeField')
      throw new Error(
        `getAnswerOption: Slide must be 'select' but it is ${slide.type}!`
      )

    const answerOption = slide.answerOptions.find(
      answerOption => answerOption.id === id
    )
    if (answerOption === undefined)
      throw new Error(`AnswerOption with ID ${id} not found!`)

    return answerOption
  },
  getOptionsResultByIdAndSlideId: demoState => (
    id: TOptionResult['id'],
    slideId: ISelectSlide['id']
  ) => {
    const slide = demoState.slides.find(slide => slide.id === slideId)
    if (slide === undefined)
      throw new Error(`getOptionsResult: Slide with ID ${slideId} not found!`)
    if (slide.type === 'freeField')
      throw new Error(`Slide must be 'select' but it is ${slide.type}!`)

    const optionsResult = slide.optionsResult.find(
      optionsResult => optionsResult.id === id
    )
    if (optionsResult === undefined)
      throw new Error(`OptionsResult with ID ${id} not found!`)

    return optionsResult
  },
  getFieldByFieldIdAndSlideId: demoState => (
    fieldId: IField['id'],
    slideId: IFreeFieldsSlide['id']
  ) => {
    const slide = demoState.slides.find(slide => slide.id === slideId)
    if (slide === undefined)
      throw new Error(`getField: Slide with ID ${slideId} not found!`)
    if (slide.type === 'select')
      throw new Error(`Slide must be 'freeField' but it is ${slide.type}!`)

    const field = slide.fields.find(field => field.id === fieldId)
    if (field === undefined)
      throw new Error(`Field with ID ${fieldId} not found!`)

    return field
  },
  getOptionByFieldIdAndOptionId: demoState => (
    slideId: IFreeFieldsSlide['id'],
    fieldId: IField['id'],
    optionId: IOption['id']
  ) => {
    const slide = demoState.slides.find(slide => slide.id === slideId)
    if (slide === undefined)
      throw new Error(`getOption: Slide with ID ${slideId} not found!`)
    if (slide.type === 'select')
      throw new Error(`Slide must be 'freeField' but it is ${slide.type}!`)

    const field = slide.fields.find(field => field.id === fieldId)
    if (field === undefined)
      throw new Error(`Field with ID ${fieldId} not found!`)

    if (field.type !== 'input') throw new Error(`Field type has to be input.`)
    if (field.inputType !== 'checkbox' && field.inputType !== 'radio')
      throw new Error(`Input Type has to be checkbox or radio.`)

    return field.options.find(option => option.id === optionId)
  },
  getOptionsResultByFieldIdAndSlideId: demoState => (
    fieldId: IField['id'],
    slideId: IFreeFieldsSlide['id']
  ) => {
    const slide = demoState.slides.find(slide => slide.id === slideId)
    if (slide === undefined)
      throw new Error(`getField: Slide with ID ${slideId} not found!`)
    if (slide.type === 'select')
      throw new Error(`Slide must be 'freeField' but it is ${slide.type}!`)

    const optionResult = slide.optionsResult.find(
      optionResult => optionResult.id === fieldId
    )
    if (optionResult === undefined)
      throw new Error(`OptionResult with ID ${fieldId} not found!`)

    return optionResult
  }
}
